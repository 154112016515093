// extracted by mini-css-extract-plugin
export var footerContentContainer = "component-module--footerContentContainer--6y0Db";
export var footerContentBlocks = "component-module--footerContentBlocks--0b8dD";
export var footerHead = "component-module--footerHead--agFU5";
export var openingHours = "component-module--openingHours--q-sNg";
export var departmentBlock = "component-module--departmentBlock--kf7o0";
export var footerContentLink = "component-module--footerContentLink--4aWF5";
export var menu = "component-module--menu--DXlFa";
export var link = "component-module--link--j8ZMu";
export var footerNavigation = "component-module--footerNavigation--Ux67f";
export var buttonCookieSettings = "component-module--buttonCookieSettings--iDbPU";
export var socialMediaWrapper = "component-module--socialMediaWrapper--XQYoe";
export var socialMediaButtonList = "component-module--socialMediaButtonList--nGbqN";
export var socialMediaButton = "component-module--socialMediaButton--mYtJE";
export var footerTail = "component-module--footerTail--J8Ep2";
export var footerPageLinkContainer = "component-module--footerPageLinkContainer--fsVrV";
export var pageLinkGroup = "component-module--pageLinkGroup--i929D";
export var copyright = "component-module--copyright--TEBe0";