import React, { useState } from "react";
import * as styles from "./component.module.less";
import Search from '../../lib/services/search'
import { Link } from "gatsby";
import createHtmlClass from "../../lib/helper/createHtmlClass";
import t from "../../lib/translate";

export default function MobileSearch () {
    const SearchService = new Search(process.env.GATSBY_API_SEARCH_URL)
    const inputSearchRef = React.createRef();

    const [searchString, setSearchString] = useState('')
    const [isSearching, setIsSearching] = useState(false)
    const [response, setResponse] = useState({})
    const [hasResponded, setHasResponded] = useState(false)

    function search() {
        const inputValue = inputSearchRef?.current?.value
        
        setHasResponded(false)
        setIsSearching(true)
        setSearchString(inputValue)

        if (inputValue) {
            SearchService.find(inputValue).then(result => {
                
                const searchResult = result
                searchResult.data = result.data
                .filter(enty => enty.attributes.score >= 1)
                .map(entry => {
                    const newEntry = entry
                    newEntry.attributes.url = newEntry.attributes.url
                        .replace(/^(https?:\/\/)?[^/]+(.*)$/g, ($0, $1, $2) => $2)
                    return newEntry
                })
                
                console.log(searchResult)
                setIsSearching(false)
                setResponse(searchResult)
                setHasResponded(true)
            })
        }
    }

    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            search()
        }
    }

    return(
        <div className={createHtmlClass([styles.searchContainer, hasResponded ? styles.withResults : ''])}>
            <div className={styles.searchInputWrapper}>
                <input 
                    type='text' 
                    className={styles.inputSearch} 
                    placeholder='Was suchen Sie ?'
                    ref={inputSearchRef}
                    onKeyDown={e => handleKeyDown(e)}
                />
                <button 
                    className='btn-dark'
                    onClick={search}
                >
                    Suchen
                </button>
            </div>

            {(isSearching || hasResponded) && searchString &&
                <div className={styles.searchResults}>
                    {searchString && isSearching &&
                        <p>{t`page.search-result.message.loading`}</p>
                    }

                    {(hasResponded && response?.data?.length === 0) &&
                        <p>{t('page.search-result.message.no-results', [searchString ?? ''])}</p>
                    }

                    {response?.data?.map(searchResult => (
                        <Link 
                            key={searchResult.id}
                            className={styles.result}
                            to={searchResult.attributes.url}
                        >
                            <div className={styles.resultContent}>
                                <span>{searchResult.attributes.title}</span>
                                <p>{`${searchResult.attributes.body_excerpt}...`}</p>
                            </div>

                            <div className='icon-btn btn-arrow-right'></div>
                        </Link>
                    ))}
                </div>
            }
        </div>
    )
}