import React from "react";
import createHtmlClass from "../../lib/helper/createHtmlClass";
import * as styles from "./component.module.less";

const SectionContainer = (props) => {
  const { 
    background,
    children,
    className,
    sectionClass,
    mediaAlignRight,
    noPadding
  } = props

  const containerClasses = [ styles.sectionContainer, className ];
  const contentSectionClasses = [ styles.contentSection, sectionClass, noPadding ? styles.containerNoPadding : '' ];
  
  if (background === "dunkel") {
    containerClasses.push(styles.dark);
  } else {
    containerClasses.push(styles.light);
  }

  if (mediaAlignRight) {
    contentSectionClasses.push(styles.alignRight);
  }

  return (
    <section className={createHtmlClass(containerClasses)}>
      <div className={createHtmlClass(contentSectionClasses)}>
        {children}
      </div>
    </section>
  );
};

export default SectionContainer;
