import React, { Fragment } from "react";
import { Link } from "gatsby";
import * as styles from "./component.module.less";
import FlyoutMobile from "../../flyoutMobile";
import MobileSearch from "../../mobile-search";

class MobileNavigation extends React.Component {
        
    constructor (props) {

        super(props);
        
        this.state = {
            activeFlyout: null,
            activeLayer: 'main'
        }

        this.referenceMenu = React.createRef();
        this.referenceSearch = React.createRef();
        this.referenceLogin = React.createRef();

        this.toggleFlyout = this.toggleFlyout.bind(this)
        this.switchLayer = this.switchLayer.bind(this)
    }

    toggleFlyout(e, flyout) {
        switch (flyout) {
            case 'menu':
                this.switchLayer(e, 'main');
                this.referenceMenu.current.toggleFlyout();
                break;
            case 'search':
                this.referenceSearch.current.toggleFlyout();
                break;
            case 'login':
                this.referenceLogin.current.toggleFlyout();
                break;
            default:
                return
        }
    }

    switchLayer(e, id) {
        let layer = 'main'
        if (id !== null) {
            layer = id
        }
        
        this.setState(() => ({
            activeLayer: layer
        }))
    }

    render() {
        return (
            <div className={styles.navMobile}>
                <div className={`${styles.navBarMobile} ${styles.small}`}>
                    <Link 
                        to={this.props.locale ? `/${this.props.locale}/` : '/'} 
                        className={`${styles.navBarLogo} logo-xs`}
                    />
                    <div className={styles.nav_buttons}>
                        <button 
                            className={`icon-btn btn-search`}
                            onClick={(e) => this.toggleFlyout(e, 'search')}
                        >
                            Suche
                        </button>
                        <button 
                            className={`icon-btn btn-login`}
                            onClick={(e) => this.toggleFlyout(e, 'login')}
                        >
                            Login
                        </button>
                        <button 
                            className={`icon-btn btn-menu`}
                            onClick={(e) => this.toggleFlyout(e, 'menu')}
                        >
                            Menu
                        </button>
                    </div>
                </div>
    
                <FlyoutMobile id='menu' ref={this.referenceMenu}>
                    <nav className={styles.navContainer}>
                        {/* renders sub page menu enableTopLevelMenu = true in nav-top */}
                        {this.props.topLevelElements.length > 0 && 
                            <>
                                {this.state.activeLayer === 'main' &&
                                    this.props.topLevelElements.map(element => (
                                        <button
                                            key={element.id}
                                            className={styles.navItem}
                                            onClick={(e) => this.switchLayer(e, element.id)}
                                        >
                                            {element.name}
                                            <div className='icon-btn btn-arrow-right-grey'/>
                                        </button>
                                    )
                                )}

                                {this.props.topLevelElements.map(element => (
                                    <Fragment key={element.id}>
                                        {(this.state.activeLayer === element.id) &&
                                            <>
                                                <button 
                                                    className={`${styles.navItem} ${styles.backButton}`} 
                                                    onClick={(e) => {this.switchLayer(e, 'main')}}
                                                >
                                                    <div className='icon-btn btn-arrow-right-grey'/>
                                                    zurück
                                                </button>
                                                {element.treeChildren.map((navElement) => {
                                                    if(navElement?.treeChildren.length) {
                                                        return(
                                                            <button 
                                                                key={navElement.id}
                                                                className={styles.navItem}
                                                                onClick={(e) => {this.switchLayer(e, navElement.id)}}
                                                            >
                                                                {navElement.name}
                                                                <div className='icon-btn btn-arrow-right-grey'/>
                                                            </button>
                                                        )
                                                    } else {
                                                        return(
                                                            <Link 
                                                                key={navElement.id} 
                                                                className={styles.navItem} 
                                                                to={this.props.locale ? `/${this.props.locale}/${navElement?.link?.slug}` : `/${navElement?.link?.slug}`}
                                                            >
                                                                {navElement.name}
                                                                <div className='icon-btn btn-arrow-right-grey'/>
                                                            </Link>
                                                        )
                                                    }
                                                })}
                                            </>
                                        }
                                    </Fragment>
                                ))}
                            </>
                        }

                        {(this.props.topLevelElements.length <= 0 && this.state.activeLayer === 'main') &&
                            this.props.navElements.map((navElement) => {
                                if(navElement?.treeChildren.length) {
                                    return(
                                        <button 
                                            key={navElement.id}
                                            className={styles.navItem}
                                            onClick={(e) => {this.switchLayer(e, navElement.id)}}
                                        >
                                            {navElement.name}
                                            <div className='icon-btn btn-arrow-right-grey'/>
                                        </button>
                                    )
                                } else {
                                    return(
                                        <Link 
                                            key={navElement.id} 
                                            className={styles.navItem} 
                                            to={this.props.locale ? `/${this.props.locale}/${navElement?.link?.slug}` : `/${navElement?.link?.slug}`}
                                        >
                                            {navElement.name}
                                            <div className='icon-btn btn-arrow-right-grey'/>
                                        </Link>
                                    )
                                }
                            })
                        }

                        {
                            Object.entries(this.props.subNavigations).map(entry => {
                                const [ id, childList ] = entry;
                                return(
                                    <Fragment key={id}>
                                        {this.state.activeLayer === id &&
                                            <div>
                                                <button 
                                                    className={`${styles.navItem} ${styles.backButton}`} 
                                                    onClick={(e) => {this.switchLayer(e, this.props.layerMap[id])}}
                                                >
                                                    <div className='icon-btn btn-arrow-right-grey'/>
                                                    zurück
                                                </button>
                                                {
                                                    childList.map((navChild) => {
                                                        if (navChild?.treeChildren.length) {
                                                            return (
                                                                <button 
                                                                    key={navChild.id}
                                                                    className={styles.navItem}
                                                                    onClick={(e) => {this.switchLayer(e, navChild.id)}}
                                                                >
                                                                    {navChild.name}
                                                                    <div className='icon-btn btn-arrow-right-grey'/>
                                                                </button>
                                                            )
                                                        }
                                                        return(
                                                            <Link 
                                                                key={navChild.id} 
                                                                className={styles.navItem} 
                                                                to={this.props.locale ? `/${this.props.locale}/${navChild?.link?.slug}` : `/${navChild?.link?.slug}`}
                                                            >
                                                                {navChild.name}
                                                            </Link>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </Fragment>
                                )
                            })
                        }

                        {/* {
                            this.hasChilds.map((navElement) => {
                                return(
                                    <Fragment key={navElement.id}>
                                        {this.state.activeLayer === navElement.id &&
                                            <div>
                                                <button className={`${styles.navItem} ${styles.backButton}`} onClick={(e) => {this.switchLayer(e, 'main')}}>
                                                    <div className='icon-btn btn-arrow-right'/>
                                                    zurück
                                                </button>
                                                {
                                                    navElement.treeChildren.map((navChild) => {
                                                        return(
                                                            <Link 
                                                                key={navChild.id} 
                                                                className={styles.navItem} 
                                                                to={this.props.locale ? `/${this.props.locale}/${navChild?.link?.slug}` : `/${navChild?.link?.slug}`}
                                                            >
                                                                {navChild.name}
                                                                <div className='icon-btn btn-arrow-right'/>
                                                            </Link>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </Fragment>
                                )
                            })
                        } */}
                    </nav>
                </FlyoutMobile>

                <FlyoutMobile id='search' ref={this.referenceSearch}>
                    <MobileSearch />
                </FlyoutMobile>

                <FlyoutMobile id='login' ref={this.referenceLogin}>
                    {/* TODO: Implement login module */}
                </FlyoutMobile>
            </div>
        )
    }
}

export default MobileNavigation;