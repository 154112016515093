const translation = {
'pdf.cancellation-form.business_hours': 'Geschäftszeiten',
'pdf.cancellation-form.headline': 'Ihre Kündigungsquittung',
'pdf.cancellation-form.letter': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Guten Tag,"}]},{"type":"paragraph","children":[{"type":"span","value":"schade, dass Sie gehen möchten. Dies ist Ihre Kündigung."}]}]}},"links":[],"blocks":[]},
'pdf.cancellation-form.label.date_and_time': 'Eingangszeitpunkt',
'pdf.cancellation-form.label.product': 'Produkt',
'pdf.cancellation-form.label.counter_number': 'Zähler-/Vertragsnummer',
'pdf.cancellation-form.label.customer_number': 'Kundenummer',
'pdf.cancellation-form.label.termination': 'Art der Kündigung',
'pdf.cancellation-form.label.reason': 'Kündigungsgrund',
'pdf.cancellation-form.label.request_for': 'Kündigungswunsch zum',
'pdf.cancellation-form.label.message': 'Warum verlassen Sie uns?',
'pdf.cancellation-form.personal_data': 'Persönliche Daten',
'pdf.cancellation-form.salutation': 'Anrede',
'pdf.cancellation-form.firstname': 'Vorname',
'pdf.cancellation-form.lastname': 'Name',
'pdf.cancellation-form.address': 'Adresse',
'pdf.cancellation-form.zip': 'Postleitzahl',
'pdf.cancellation-form.city': 'Ort',
'pdf.cancellation-form.email': 'E-Mail',
'pdf.cancellation-form.explanation': 'Die Kündigungsquittung ist Ihre Bestätigung über den Eingang Ihres Kündigungswunsches. Eine Bestätigung des Liefernden erfolgt schriftlich gemäß der gesetzlichen Fristen, nach der Bearbeitung.',
'pdf.cancellation-form.footer': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","marks":["strong"],"value":"Stadtwerke OELSNITZ/V. GmbH"},{"type":"span","value":" Tel 037421 408-0 · Fax 037421 29491 "},{"type":"span","marks":["strong"],"value":"info@swoe.de"}]},{"type":"paragraph","children":[{"type":"span","marks":["strong"],"value":"VR Bank Hof"},{"type":"span","value":" IBAN DE62 7806 0896 0103 5560 77 · BIC GENODEF1HO1"}]},{"type":"paragraph","children":[{"type":"span","marks":["strong"],"value":"Sparkasse Vogtland"},{"type":"span","value":" IBAN DE88 8705 8000 3704 0017 23 · BIC WELADED1PLX"}]},{"type":"paragraph","children":[{"type":"span","marks":["strong"],"value":"Geschäftsführerin"},{"type":"span","value":" Ines Puhan "},{"type":"span","marks":["strong"],"value":"Aufsichtsratvorsitzender"},{"type":"span","value":" Mario Horn"}]},{"type":"paragraph","children":[{"type":"span","marks":["strong"],"value":"Registergericht Chemnitz"},{"type":"span","value":" HRB 8499 "},{"type":"span","marks":["strong"],"value":"USt-ID"},{"type":"span","value":" DE157373455"}]}]}},"links":[],"blocks":[]},
'pdf.cancellation-form.field': 'Bereich',
'pdf.cancellation-form.telefon': 'Telefon',
'pdf.cancellation-form.date': 'Datum',
'pdf.cancellation-form.page': 'Seite von',
'pdf.cancellation-form.pdf_filename_base': 'Kündigung',
'page.footer.navigation-headline': 'Service und Informationen',
'page.footer.opening-hours': 'Öffnungszeiten',
'page.footer.social-media': 'Sie finden uns auch auf',
'page.footer.copyright': '©%v Stadtwerke OELSNITZ/V. GmbH',
'page.footer.cookie-settings': 'Cookie-Einstellungen',
'page.broadband-product.taxhint': 'monatlich inkl. 19% MwSt.',
'page.broadband-product.downloadspeed': 'Mbit/s Downloadgeschwindigkeit',
'page.broadband-product.uploadspeed': 'Mbit/s Uploadgeschwindigkeit',
'page.broadband-product.contractduration': 'Monate Mindestvertragslaufzeit',
'page.broadband-product.download-link': 'Produktinformation',
'page.broadband-product.promotion-label': 'Angebot',
'page.broadband-product.promotion-price-label': 'nur',
'page.broadband-product.button.select-plan': 'Verfügbarkeit prüfen',
'page.wlan-plan.download-link': 'Nutzungsbedingungen',
'page.plan-request.header': 'Tarifanfrage',
'page.plan-request.subheader': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Sie möchten einen konkreten Breitband-Tarif der Stadtwerke Oelsnitz GmbH anfragen oder haben Fragen zu unseren Tarifen? Dann wenden Sie sich gern über dieses Formular an uns."}]}]}},"links":[],"blocks":[]},
'page.plan-request.error-message': 'Dies ist ein Pflichtfeld.',
'page.plan-request.label.plan': 'Tarif',
'page.plan-request.label.plan.values': 'Oelsnitz.Media 50,Oelsnitz.Media 125,Oelsnitz.Media 300,Oelsnitz.Media 500,Oelsnitz.Media 1000,Oelsnitz.Media K50,Oelsnitz.Media K100',
'page.plan-request.plan.error-message': 'Bitte wählen Sie einen Tarif.',
'page.plan-request.placeholder.plan': 'Tarif auswählen',
'page.plan-request.label.salutation': 'Anrede',
'page.plan-request.placeholder.salutation': 'Bitte auswählen',
'page.plan-request.label.salutation.values': 'Herr,Frau,divers',
'page.plan-request.label.firstname': 'Vorname',
'page.plan-request.label.lastname': 'Nachname',
'page.plan-request.label.email': 'E-Mail-Adresse',
'page.plan-request.invalid.email': 'Dies ist keine korrekte E-Mail-Adresse.',
'page.plan-request.label.phone': 'Telefon',
'page.plan-request.label.street': 'Strasse',
'page.plan-request.label.number': 'Hausnummer',
'page.plan-request.label.zip': 'PLZ',
'page.plan-request.label.city': 'Ort',
'page.plan-request.label.message': 'Ihre Nachricht',
'page.plan-request.label.callback': 'Möchten Sie, dass wir Sie telefonisch kontaktieren?',
'page.plan-request.callback.value1': 'Ja, ich bitte um Rückruf.',
'page.plan-request.label.data-process': 'Weiterverarbeitung',
'page.plan-request.data-process.value1': 'Bitte schicken Sie mir die Vertragsunterlagen vorausgefüllt zu.',
'page.plan-request.data-process.value2': 'Bitte bereiten Sie die Vertragsunterlagen vor. Ich komme im Kundencenter vorbei.',
'page.plan-request.label.gdpr': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Ja, ich habe die "},{"item":"63862949","meta":[{"id":"target","value":"_blank"},{"id":"title","value":"Zum Datenschutz"}],"type":"itemLink","children":[{"type":"span","value":"Datenschutzerklärung"}]},{"type":"span","value":" gelesen und akzeptiere diese hiermit.\n"}]}]}},"links":[{"id":"63862949","slug":"datenschutz"}],"blocks":[]},
'page.no-address-form.error-message': 'Dies ist ein Pflichtfeld.',
'page.no-address-form.header': 'Adresse nicht verfügbar?',
'page.no-address-form.subheader': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Ihre Adresse ist nicht im System vorhanden? Teilen Sie uns Ihre Adresse mit. Wir werden diese dann überprüfen."}]}]}},"links":[],"blocks":[]},
'page.no-address-form.label.firstname': 'Vorname',
'page.no-address-form.label.lastname': 'Nachname',
'page.no-address-form.label.email': 'E-Mail-Adresse',
'page.no-address-form.label.phone': 'Telefon',
'page.no-address-form.invalid.email': 'Dies ist keine korrekte E-Mail-Adresse.',
'page.no-address-form.label.missing-address': 'Fehlende Adresse bei Anfrage',
'page.no-address-form.placeholder.missing-address': 'Geben Sie bitte die Adresse an, welche nicht gefunden wurde: Straße, Hausnummer, PLZ, Ort.',
'page.no-address-form.label.message': 'Ihre Nachricht',
'page.no-address-form.label.callback': 'Möchten Sie, dass wir Sie telefonisch kontaktieren?',
'page.no-address-form.callback.value': 'Ja, ich bitte um Rückruf.',
'page.no-address-form.label.gdpr': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Ja, ich habe die "},{"item":"63862949","meta":[{"id":"target","value":"_blank"},{"id":"title","value":"Zum Datenschutz"}],"type":"itemLink","children":[{"type":"span","value":"Datenschutzerklärung"}]},{"type":"span","value":" gelesen und akzeptiere diese hiermit.\n"}]}]}},"links":[{"id":"63862949","slug":"datenschutz"}],"blocks":[]},
'page.contact-form.header': 'Kontaktformular',
'page.contact-form.subheader': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Wir freuen uns auf Ihre Anfrage zu unseren Produkten und Dienstleistungen."}]}]}},"links":[],"blocks":[]},
'page.contact-form.error-message': 'Dies ist ein Pflichtfeld.',
'page.contact-form.label.salutation': 'Anrede',
'page.contact-form.placeholder.salutation': 'Bitte auswählen',
'page.contact-form.label.salutation.values': 'Herr,Frau,divers',
'page.contact-form.label.firstname': 'Vorname',
'page.contact-form.placeholder.firstname': 'Bitte geben Sie Ihren Vornamen ein.',
'page.contact-form.label.lastname': 'Nachname',
'page.contact-form.placeholder.lastname': 'Bitte geben Sie Ihren Nachname ein.',
'page.contact-form.label.email': 'E-Mail-Adresse',
'page.contact-form.placeholder.email': 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
'page.contact-form.invalid.email': 'Dies ist keine korrekte E-Mail-Adresse.',
'page.contact-form.label.phone': 'Telefon',
'page.contact-form.placeholder.phone': 'Bitte geben Sie Ihre Telefonnummer ein.',
'page.contact-form.label.message': 'Nachricht',
'page.contact-form.placeholder.message': 'Bitte geben Sie Ihre Nachricht ein.',
'page.contact-form.label.gdpr': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Ja, ich habe die "},{"item":"63862949","meta":[{"id":"target","value":"_blank"},{"id":"title","value":"Zum Datenschutz"}],"type":"itemLink","children":[{"type":"span","value":"Datenschutzerklärung"}]},{"type":"span","value":" gelesen und akzeptiere diese hiermit.\n"}]}]}},"links":[{"id":"63862949","slug":"datenschutz"}],"blocks":[]},
'page.contact-form.button.back': 'Zurück',
'page.contact-form.button.send': 'Absenden',
'page.contact-form.send.successful.header': 'Erfolgreich versendet',
'page.contact-form.send.successful.message': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Wir haben Ihre Nachricht erhalten und werden diese zeitnah beantworten."}]}]}},"links":[],"blocks":[]},
'page.forms.back-button': 'zurück',
'page.forms.submit-button': 'Absenden',
'page.cancellation-forms.back-cancelContractButton': 'Verträge hier kündigen',
'page.form-success.header': 'Ihr Formular wurde erfolgreich versendet.',
'page.form.general.error': 'Etwas ist schiefgelaufen. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es später erneut.',
'page.form-contact-info.header': 'Kontakt',
'page.form-contact-info.phone': 'Rufen Sie uns an',
'page.form-contact-info.mail': 'E-Mail',
'page.form-contact-info.fax': 'Fax',
'page.search-form.placeholder': 'Suchbegriff eingeben',
'page.search-form.button': 'Suchen',
'page.search-result.headline': 'Suchergebnisse für "%v"',
'page.search-result.message.no-search-string': 'Bitte geben Sie einen Suchbegriff ein.',
'page.search-result.message.loading': 'Suchergebnisse werden gesammelt...',
'page.search-result.message.no-results': 'Leider wurden keine Ergebnisse für "%v" gefunden.',
'page.icon-teaser.hovertext': 'Mehr erfahren',
'page.serviceteaser.introduction': 'Besuchen Sie uns im',
'page.serviceteaser.phoneintro': 'Rufen Sie uns an',
'page.serviceteaser.email': 'Schreiben Sie uns eine E-Mail an',
'test': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Test test test"}]},{"type":"list","style":"bulleted","children":[{"type":"listItem","children":[{"type":"paragraph","children":[{"type":"span","value":"test"}]}]}]}]}},"links":[],"blocks":[]},
'page.cancellation-form.header': 'Kündigungswunsch Verträge',
'page.cancellation-form.subheader': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":""}]}]}},"links":[],"blocks":[]},
'page.cancellation-form.label.salutation': 'Anrede',
'page.cancellation-form.placeholder.salutation': 'Bitte auswählen',
'page.cancellation-form.label.salutation.values': 'Herr,Frau,divers',
'page.cancellation-form.label.firstname': 'Vorname',
'page.cancellation-form.placeholder.firstname': 'Bitte geben Sie Ihren Vornamen ein.',
'page.cancellation-form.label.lastname': 'Nachname',
'page.cancellation-form.placeholder.lastname': 'Bitte geben Sie Ihren Nachnamen ein.',
'page.cancellation-form.label.street': 'Straße',
'page.cancellation-form.label.number': 'Hausnummer',
'page.cancellation-form.label.city': 'Ort',
'page.cancellation-form.label.zip': 'PLZ',
'page.cancellation-form.label.product': 'Produkt',
'page.cancellation-form.placeholder.product': 'Bitte auswählen',
'page.cancellation-form.label.product.energy': 'Strom',
'page.cancellation-form.label.product.gas': 'Gas',
'page.cancellation-form.label.product.internet': 'Internet/Telefon/ TV',
'page.cancellation-form.label.counter_number': 'Zählernummer (Strom, Gas) bzw. Vertragsnummer (Internet/ Telefon/ TV)',
'page.cancellation-form.label.customer_number': 'Kundenummer',
'page.cancellation-form.label.cancellation': 'Art der Kündigung',
'page.cancellation-form.placeholder.cancellation': 'Bitte auswählen',
'page.cancellation-form.label.cancellation.values': 'ordentliche Kündigung, außerordentliche Kündigung',
'page.cancellation-form.label.reason_of_cancellation': 'Kündigungsgrund',
'page.cancellation-form.placeholder.reason_of_cancellation': 'Bitte auswählen',
'page.cancellation-form.label.reason_of_cancellation.values': 'Umzug, Lieferanten- bzw. Anbieterwechsel, Sonstiges',
'page.cancellation-form.label.date_of_cancellation': 'Kündigungszeitpunkt',
'page.cancellation-form.label.email': 'E-Mail-Adresse',
'page.cancellation-form.placeholder.email': 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
'page.cancellation-form.invalid.email': 'Dies ist keine korrekte E-Mail-Adresse.',
'page.cancellation-form.label.message': 'Warum verlassen Sie uns?',
'page.cancellation-form.placeholder.message': 'Bitte geben Sie Ihre Nachricht ein.',
'page.cancellation-form.label.gdpr': {"value":{"schema":"dast","document":{"type":"root","children":[{"type":"paragraph","children":[{"type":"span","value":"Ja, ich habe die "},{"item":"63862949","meta":[{"id":"target","value":"_blank"},{"id":"title","value":"Zum Datenschutz"}],"type":"itemLink","children":[{"type":"span","value":"Datenschutzerklärung"}]},{"type":"span","value":" gelesen und akzeptiere diese hiermit."}]}]}},"links":[{"id":"63862949","slug":"datenschutz"}],"blocks":[]},
'page.cancellation-forms.back-cancelContractButton': 'Verträge hier kündigen',
'page.cancellation-forms.back-cancelNowButton': 'Jetzt kündigen',
'page.cancellation-form.error-message': 'Dies ist ein Pflichtfeld!',
'page.cancellation-form.select.error-message': 'Bitte wählen Sie eine Option.',
'page.cancellation-forms.submit-button.label': 'Jetzt kündigen',
'page.cancellation-forms.message-limit.error-message': 'Die Nachricht ist zu lang.',
};

export default translation