/**
 * This function transforms the given input to valid HTML class string.
 * 
 * @param {string|Array.<string>} classes list of classes or string
 * @returns {string} valid HTML class string
 */
export default function createHtmlClass(classes) {
    let classString = ''

    if(typeof classes === 'string') {
        classString = classes;
    } else if (Array.isArray(classes)) {
        classes = classes.filter(c => c?.length).map(c => c.trim())
        classString = classes.join(' ');
    } else {
        throw new Error('The input needs to be either String or Array!')
    }

    return classString.trim() || null
}