import { Link } from 'gatsby'
import React, { useState } from 'react'
import createHtmlClass from '../../lib/helper/createHtmlClass'
import * as styles from './components.module.less'

export default function ContactFlag({ serviceContacts, contactPage, cancellationPage, locale }) {
    const [ active, setActive ] = useState(false)

    return (
			<div
				className={createHtmlClass([
					styles.stickyContact,
					active ? styles.active : "",
				])}
			>
				<button
					className={styles.contactFlag}
					onClick={() => setActive(!active)}
				>
					{"Kontakt".toUpperCase()}
				</button>

				<div className={styles.contactContainer}>
					{serviceContacts.map((contact) => (
						<div className={styles.contactFlagContentBlock} key={contact.id}>
							<div className={styles.headline}>{contact.name}</div>
							{contact.phone && (
								<p>
									<a
										className={createHtmlClass([styles.contactPhone])}
										href={`tel:${contact.phone}`}
									>
										{contact.phone}
									</a>
									<br />
								</p>
							)}
							{contact.eMail && (
								<p>
									<a
										className={createHtmlClass([styles.contactEmail])}
										href={`mailto:${contact.eMail}`}
									>
										{contact.eMail}
									</a>
								</p>
							)}
						</div>
					))}

					<div className={styles.contactFlagButtonContainer}>
						<Link
							className="btn-dark"
							to={
								locale
									? `/${locale}/${contactPage.slug}`
									: `/${contactPage.slug}`
							}
						>
							{contactPage.name}
						</Link>

						<Link
							className="btn-light"
							to={
								locale
									? `/${locale}/${cancellationPage.slug}`
									: `/${cancellationPage.slug}`
							}
						>
							Kündigung
						</Link>
					</div>
				</div>
			</div>
		);
}
