export default class Search {

    baseUrl = '';
    locale = '';
    scoreThreshold = 0.01;
  
    /**
     * constructor
     *
     * @param {string} baseUrl
     * @param {string} locale
     */
    constructor(baseUrl, locale) {
      this.baseUrl = baseUrl;
      this.locale = locale;
    }
  
    /**
     *
     *
     * @param {string} query
     *
     * @return {Promise<Object>}
     */
    find(query) {
      return new Promise(((resolve, reject) => {
        const url = `${this.baseUrl}?q=${query}${this.locale ? `&locale=${this.locale}`: ''}`;
        
  
        fetch(new Request(url, {
          method: 'GET',
        }))
            .then(res => {
              if (!res.ok) {
                return Promise.reject(new Error('response error "' + res.statusText + '" with status ' + res.status + ' for request: GET ' + url));
              }
  
              return res;
            })
            .then(res => res.json())
            .then(res => {
              // filter low-score results
              res.data = res.data.filter(r => r.attributes.score > this.scoreThreshold);
              resolve(res);
            })
            .then(
                () => {},
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (e) => {
                  reject(e);
                }
            );
      }));
    }
  }
  