import React, { Fragment } from "react";
import * as styles from "./component.module.less";

class FlyoutMobile extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            active: false
        }

        this.toggleFlyout = this.toggleFlyout.bind(this)
    }

    toggleFlyout() {
        this.setState(() => ({
            active: !this.state.active
        }))
    }

    render() {
        return(
            <Fragment>
                <div id={this.props.id} className={this.state.active ? `${styles.flyoutMenu} ${styles.flyoutActive}` : styles.flyoutMenu}>
                    <div className={styles.flyoutHeader}>
                        <div className={`${styles.flyoutLogo} logo-xs`}/>
                        <button 
                            className={`icon-btn btn-close`}
                            onClick={this.toggleFlyout}
                        >
                            Close
                        </button>
                    </div>

                    {this.props.children}
                </div>
        
                <div className={this.state.active ? `${styles.flyoutBackground} ${styles.active}` : styles.flyoutBackground} />
            </Fragment>
        )
    }
    
}

export default FlyoutMobile