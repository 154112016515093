import React from "react";
import * as styles from "./component.module.less";
import { graphql, Link, StaticQuery } from "gatsby";
import SectionContainer from "../section-container";
import t from "../../lib/translate";
import createHtmlClass from "../../lib/helper/createHtmlClass";
import StructuredTextRenderer from "../structured-text-renderer";

const useLocale = process.env.GATSBY_USE_LOCALE === "TRUE";

export default function Footer({ locale, config, cookieConsentRef }) {
	return (
		<StaticQuery
			query={query}
			render={(data) => {
				const navElements = data.allDatoCmsNavigationFooter.nodes.filter(
					(node) => {
						return node.locale === (locale ?? "de") || useLocale;
					}
				);

				// Might need later:
				// const pageNavElements = data.allDatoCmsNavigationMain.nodes.filter(node => {
				//   return (node.locale === (locale ?? 'de') || useLocale)
				// });

				const department = config.department;

				return (
					<>
						<SectionContainer
							background="dunkel"
							sectionClass={styles.footerContentContainer}
						>
							<div className={styles.footerContentBlocks}>
								<p className={styles.footerHead}>{department.name}</p>

								<p className={styles.departmentBlock}>
									{department.street} {department.number} <br />
									{`${department.zip} ${department.place}`}
								</p>

								<p className={styles.departmentBlock}>
									{`Telefon: ${department.phone}`} <br />
									E-Mail:{" "}
									<a
										href={`mailto:${department.email}`}
										className={styles.footerContentLink}
									>
										{department.email}
									</a>
								</p>
							</div>

							<div className={styles.footerContentBlocks}>
								<p className={styles.footerHead}>
									{t("page.footer.opening-hours")}
								</p>
								<StructuredTextRenderer
									data={department.openingHours}
									noContainer
								/>
							</div>

							<div className={styles.footerContentBlocks}>
								<p className={styles.footerHead}>
									{t("page.footer.navigation-headline")}
								</p>

								<nav className={styles.footerNavigation}>
									{navElements.map((element) => {
										if (element?.link?.slug) {
											return (
												<Link
													key={element.id}
													to={
														locale
															? `/${locale}/${element?.link?.slug}`
															: `/${element?.link?.slug}`
													}
												>
													{element.name}
												</Link>
											);
										}

										return (
											<a
												key={element.id}
												href={element.externalUrl}
												target="_blank"
											>
												{element.name}
											</a>
										);
									})}
									<button
										className={styles.buttonCookieSettings}
										onClick={() => {
											if (!cookieConsentRef || !cookieConsentRef.current)
												return;
											cookieConsentRef.current.setVisible();
										}}
									>{t`page.footer.cookie-settings`}</button>
								</nav>
							</div>

							<div className={styles.footerContentBlocks}>
								<p className={styles.footerHead}>
									{t("page.footer.social-media")}
								</p>

								<div className={styles.socialMediaButtonList}>
									{config.socialMediaChannels.map((socialMediaLink) => {
										const buttonClass = [styles.socialMediaButton, "icon-btn"];

										switch (socialMediaLink.name) {
											case "Facebook":
												buttonClass.push("btn-facebook");
												break;
											case "Youtube":
												buttonClass.push("btn-youtube");
												break;
											case "Xing":
												buttonClass.push("btn-xing");
												break;
											default:
												return null;
										}

										return (
											<a
												key={socialMediaLink.id}
												className={createHtmlClass(buttonClass)}
												href={socialMediaLink.url}
												target="_blank"
												rel="noreferrer"
											>
												{socialMediaLink.name}
											</a>
										);
									})}
								</div>
							</div>
						</SectionContainer>

						<SectionContainer
							className={styles.footerTail}
							sectionClass={styles.footerPageLinkContainer}
						>
							<div className={styles.pageLinkGroup}>
								<a
									className="btn-logo-vogtland-energy icon-btn"
									href="https://www.vogtland-energie.de/seite/startseite/"
									target="_blank"
									rel="noreferrer"
								>
									Vogtland-Energie
								</a>

								<a
									className="btn-logo-pool icon-btn"
									href="https://www.elstergarten.de/"
									target="_blank"
									rel="noreferrer"
								>
									Freibad-Elstergarten
								</a>

								<a
									className="btn-logo-ice icon-btn"
									href="https://www.eisbahn-oelsnitz.de/"
									target="_blank"
									rel="noreferrer"
								>
									Eisbahn-Oelsnitz
								</a>
							</div>

							<p className={styles.copyright}>
								{t(`page.footer.copyright`, [new Date().getFullYear()])}
							</p>
						</SectionContainer>
					</>
				);
			}}
		/>
	);
}

const query = graphql`
	query PageFooterQuery {
		allDatoCmsNavigationFooter(
			sort: { fields: position, order: ASC }
			filter: { root: { eq: true } }
		) {
			nodes {
				id
				locale
				name
				externalUrl
				link {
					... on DatoCmsPage {
						id
						originalId
						slug
						internal {
							type
						}
					}
					... on DatoCmsNews {
						id
						originalId
						slug
						internal {
							type
						}
					}
				}
			}
		}
		# allDatoCmsNavigationMain(
		#   sort: { fields: position, order: ASC }
		#   filter: { root: { eq: true } }
		# ) {
		#   nodes {
		#     id
		#     name
		#     link {
		#       ... on DatoCmsPage {
		#         id
		#         originalId
		#         slug
		#         internal {
		#           type
		#         }
		#       }
		#       ... on DatoCmsNews {
		#         id
		#         originalId
		#         slug
		#         internal {
		#           type
		#         }
		#       }
		#     }
		#   }
		# }
	}
`;
