import React from "react";
import { StructuredText, renderRule} from "react-datocms";
import * as styles from "./component.module.less";
import { Link } from "gatsby";
import createHtmlClass from "../../lib/helper/createHtmlClass";
import { isLink } from "datocms-structured-text-utils";

const StructuredTextRenderer = (props) => {
    const { 
        data,
        locale,
        className,
        itemLinkClass,
        renderLinkToRecord,
        customRules,
        noContainer,
    } = props

    function transformMeta(meta) {

        const metaObject = {};
        const classes = [itemLinkClass ?? ''];

        if (meta) {
            meta.forEach(entry => {
                entry.id = entry.id.toLowerCase();
    
                if (~(['button', 'icon', 'floating', 'class'].indexOf(entry.id))) {
                    switch (entry.id) {
                        case 'button':
                            classes.push(`btn-${entry.value}`);
                            break;
                        case 'icon':
                            classes.push(`icon-${entry.value}`);
                            break;
                        case 'floating':
                            classes.push(`float-${entry.value}`);
                            break;
                        case 'class':
                            classes.push(entry.value);
                            break;
                        default:
                            break;
                    }
                } else {
                    metaObject[entry.id] = entry.value;
                }
            });
        }

        return ({ className: createHtmlClass(classes), ...metaObject });
    }

    function defaultRenderLinktoRecord({ record, children, transformedMeta }) {
        record.slug = record.slug === 'index' ? '' : record.slug;
        transformedMeta = transformedMeta || {}

        transformedMeta.className = createHtmlClass([
            itemLinkClass, 
            transformedMeta?.className || '',
            (noContainer && className) ? className : ''
        ])

        return (
            <Link
                to={locale ? `/${locale}/${record.slug}` : `/${record.slug}`}
                {...transformedMeta}
            >
                {children}
            </Link>
        );
    }

    const containerClass = createHtmlClass([styles.structuredTextContainer, className])

    const defaultLinkRenderRule = renderRule(isLink, ({ node, children, key }) => {
        const meta = transformMeta(node.meta)


        return (
            <a key={key} href={node.url} {...meta}>
                {children}
            </a>
        )
    });

    const renderStructuredText = () => (
        <StructuredText
                data={data}
                metaTransformer={({ meta }) => transformMeta(meta)}
                renderInlineRecord={() => {
                    return null;
                } }
                renderLinkToRecord={renderLinkToRecord ?? defaultRenderLinktoRecord}
                customRules={customRules ?? [defaultLinkRenderRule]}
            />
    )

    return (
        <>
            {!noContainer &&
                <div className={containerClass}>
                    {renderStructuredText()}
                </div>
            }
            {noContainer &&
                renderStructuredText()
            }
        </>
    );
} 

export default StructuredTextRenderer