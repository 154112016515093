import React from "react";
import * as styles from "./component.module.less";
import NavTop from "../../components/nav-top";
import Footer from "../../components/footer";
import ContactFlag from "../../components/contact-flag";

const Default = ({ locale, config, noFooter, cookieConsentRef, children }) => (
    <>
        <header>
            <NavTop config={config} locale={locale}/>
        </header>

        <main className={styles.mainContent}>
            {children}
        </main>

        <ContactFlag 
            serviceContacts={config.serviceHotline}
            contactPage={config.pageContact}
            cancellationPage={config.pageCancellation}
        />

        {!noFooter &&
            <footer>
                <Footer 
                    config={config}
                    cookieConsentRef={cookieConsentRef}
                />
            </footer>
        }
    </>
)
export default Default