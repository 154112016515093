/* eslint-disable */
import React from 'react';
import source from '../../../translation';
import StructuredTextRenderer from '../../components/structured-text-renderer';

const translate = (...args) => {
  if (args.length === 0) {
    return '';
  }

  const id = args[0].raw ? args[0].raw[0] : args[0];
  if (!source[id]) {
    console.warn(`translation key "${id}" not found`);
    return id;
  }

  let res = source[id];
  let className = ''
  
  for (let i = 1; i < args.length; i++) {
    // replace variables
    if (Array.isArray(args[i]) && (typeof res !== 'object')) {
      args[i].forEach(v => {
        res = res.replace(/%v/, v.raw ? v.raw[0] : v);
      });
    } else if (typeof args[i] === 'string') {
      className = args[i]
    }
  }
  
  if (typeof res === 'object') {
    return <StructuredTextRenderer className={className} data={res} />
  }

  return res;
};

export default translate;