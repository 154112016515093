// extracted by mini-css-extract-plugin
export var btnLight = "component-module--btn-light--g2517";
export var btnDark = "component-module--btn-dark--mTU29";
export var btnLink = "component-module--btn-link--cfzF0";
export var iconBtn = "component-module--icon-btn--SSNps";
export var btnSearch = "component-module--btn-search--fb-Ow";
export var btnSearchOrange = "component-module--btn-search-orange--h6kVa";
export var btnLogin = "component-module--btn-login---IqbT";
export var btnLoginOrange = "component-module--btn-login-orange--SKD7F";
export var btnCloseOrange = "component-module--btn-close-orange--A5oTN";
export var btnClose = "component-module--btn-close--4sdr9";
export var btnArrowRight = "component-module--btn-arrow-right--lDddO";
export var btnArrowRightGrey = "component-module--btn-arrow-right-grey--7+PZ5";
export var btnMenu = "component-module--btn-menu--HChtb";
export var btnLightning = "component-module--btn-lightning--O2s5v";
export var btnCancellation = "component-module--btn-cancellation--hE2s5";
export var btnPlay = "component-module--btn-play--JAhca";
export var btnFile = "component-module--btn-file--WmwC7";
export var btnFileOrange = "component-module--btn-file-orange--pv6Lo";
export var btnFacebook = "component-module--btn-facebook--AI4CW";
export var btnYoutube = "component-module--btn-youtube--z6EdS";
export var btnXing = "component-module--btn-xing--fKnia";
export var btnLogoVogtlandEnergy = "component-module--btn-logo-vogtland-energy--o+qKe";
export var btnLogoPool = "component-module--btn-logo-pool--+P74H";
export var btnLogoIce = "component-module--btn-logo-ice--XdLBV";
export var navMobile = "component-module--navMobile--7GMIn";
export var navBarMobile = "component-module--navBarMobile--tQhmA";
export var navBarLogo = "component-module--navBarLogo--u+64x";
export var nav_buttons = "component-module--nav_buttons--IHU7U";
export var small = "component-module--small--Wp5T5";
export var navContainer = "component-module--navContainer--4gdhl";
export var navItem = "component-module--navItem--phJIn";
export var backButton = "component-module--backButton--RQmGI";
export var inactive = "component-module--inactive--ILzaO";