import React from 'react'
import createHtmlClass from '../../lib/helper/createHtmlClass'
import * as styles from './component.module.less'

export default function SiteSearchBar({ className }) {
    return (
        <div className={createHtmlClass([styles.searchBarWrapper, className])}>
            <form className={styles.searchBar} action='/suchergebnis'>
                <input
                    type='text'
                    name='s'
                    className={styles.inputSearch}
                    placeholder='Was suchen Sie?'
                />

                <button className='btn-dark' type='submit'>Submit</button>
            </form>
        </div>
    )
}
