import { graphql, Link, StaticQuery } from "gatsby";
import React, { Fragment } from "react";
import createHtmlClass from "../../lib/helper/createHtmlClass";
import SiteSearchBar from "../site-search-bar";
import * as styles from "./component.module.less";
import MobileNavigation from "./mobile-navigation";
import Search from "../../lib/services/search";

const enableTopLevelMenu =
	process.env.GATSBY_MAIN_PAGE_NAVIGATION === "TRUE" ? true : false;

class NavTop extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hoveredItem: -1,
			scrolled: false,
			activePage: "DatoCmsNavigationMain-69007110-de",
			// activePage: 'DatoCmsNavigationMain-69007164-de',
			activeMenu: "",
			activeSubMenu: "",
			hoverSubMenu: "",
			activeSearchMenu: false,
			activeLogin: false,
		};

		this.searchContainer = React.createRef();
		this.SearchService = new Search(process.env.GATSBY_API_SEARCH_URL);

		this.setActivePage = this.setActivePage.bind(this);
		this.toggleSearchMenu = this.toggleSearchMenu.bind(this);
		this.toggleLoginMenu = this.toggleLoginMenu.bind(this);
	}

	setActivePage(pageId) {
		let activePage = pageId ? pageId : "";

		this.setState(() => ({
			activePage: activePage,
		}));
	}

	openMenu(elementId) {
		this.setState(() => ({
			activeMenu: elementId,
		}));
	}

	closeMenu() {
		this.setState(() => ({
			activeMenu: "",
		}));
	}

	openSubMenu(elementId) {
		this.setState(() => ({
			activeSubMenu: elementId,
		}));
	}

	closeSubMenu() {
		this.setState(() => ({
			activeSubMenu: "",
		}));
	}

	toggleSearchMenu() {
		this.setState(() => ({
			activeSearchMenu: !this.state.activeSearchMenu,
		}));
	}

	toggleLoginMenu() {
		this.setState(() => ({
			activeLogin: !this.state.activeLogin,
		}));
	}

	render() {
		return (
			<StaticQuery
				query={query}
				render={(data) => {
					const subNavigations = {};
					const layerMap = {};
					const navElements = data.allDatoCmsNavigationMain.nodes
						.find((e) => e.id === this.state.activePage)
						.treeChildren.filter((navElement) => navElement.name !== null)
						// sort the array by position value to keep the order set in the CMS
						.sort((child1, child2) => {
							return child1.position - child2.position;
						})
						.map((navElement) => {
							if (!navElement.hasOwnProperty("link") || !navElement.link) {
								navElement.link = { slug: "" };
							} else if (!navElement.link.hasOwnProperty("slug")) {
								navElement.link.slug = "";
							} else {
								navElement.link.slug =
									navElement?.link.slug === "index"
										? ""
										: navElement?.link.slug;
							}

							navElement.layer = 0;
							layerMap[navElement.id] = "main";
							return navElement;
						});

					let itterationList = navElements;
					let i = 0;

					//Generates an Object which contains all sub-navigation lists with the related parentID as key
					while (true) {
						if (i < itterationList.length) {
							let item = itterationList[i];

							if (!layerMap.hasOwnProperty(item.id)) {
								layerMap[item.id] = item.treeParent.id;
							}

							if (item?.treeChildren?.length) {
								subNavigations[item.id] = item.treeChildren
									// sort the array by position value to keep the order set in the CMS
									.sort((child1, child2) => {
										return child1.position - child2.position;
									})
									.filter((child) => !!child?.link?.slug)
									.map((child) => {
										child.link.slug =
											child?.link?.slug === "index" ? "" : child?.link?.slug;
										child.layer = item.layer + 1;
										return child;
									});

								itterationList = [...itterationList, ...item.treeChildren];
							}
						} else {
							break;
						}
						i++;
					}

					return (
						<>
							<div className={styles.navDesktop}>
								<div
									className={
										enableTopLevelMenu
											? `${styles.navBarDesktop} ${styles.topLevelEnabled}`
											: styles.navBarDesktop
									}
								>
									<Link
										to={this.props.locale ? `/${this.props.locale}/` : "/"}
										className={
											enableTopLevelMenu
												? `${styles.navBarLogo} logo-lg`
												: `${styles.navBarLogo} logo-md`
										}
									/>

									<div
										className={`${styles.navWrapper} ${styles.topLevelEnabled}`}
									>
										{/* renders sub page selection menu */}
										{enableTopLevelMenu && (
											<nav className={styles.topLevelNavigation}>
												{data.allDatoCmsNavigationMain.nodes.map(
													(topLevelItem) => (
														<button
															key={topLevelItem.id}
															className={
																this.state.activePage === topLevelItem.id
																	? `${styles.topLevelItem} ${styles.active}`
																	: styles.topLevelItem
															}
															onClick={(e) =>
																this.setActivePage(topLevelItem.id)
															}
														>
															{topLevelItem.name}
														</button>
													)
												)}
											</nav>
										)}

										<nav className={styles.navContainer}>
											{navElements.map((el) => {
												const isActive = this.state.activeMenu === el.id;
												const link = this.props.locale
													? `/${this.props.locale}/${el?.link?.slug}`
													: `/${el?.link?.slug}`;

												return (
													<Link
														key={el.id}
														to={link}
														className={
															isActive
																? `${styles.navItem} ${styles.active}`
																: styles.navItem
														}
														onMouseOver={() => {
															this.closeSubMenu();

															if (!el?.treeChildren.length) {
																this.closeMenu();
															} else {
																this.openMenu(el.id);
															}
														}}
													>
														<div className={styles.link}>{el.name}</div>
													</Link>
												);
											})}
										</nav>
									</div>

									<div
										className={`${styles.navBackground} ${styles.topLevelEnabled}`}
									/>

									<div className={styles.menuIconContainer}>
										<button
											className={createHtmlClass([
												styles.menuIconBlock,
												this.state.activeSearchMenu ? styles.active : "",
											])}
											onClick={this.toggleSearchMenu}
										>
											<span
												className={createHtmlClass([
													"icon-btn",
													this.state.activeSearchMenu
														? "btn-search-orange"
														: "btn-search",
												])}
											></span>
											<span className={styles.menuText}>Suche</span>
										</button>

										<a
											className={createHtmlClass([
												styles.menuIconBlock,
												this.state.activeLogin ? styles.active : "",
											])}
											href={this.props.config.linkToLogin}
											target="_blank"
										>
											<span
												className={createHtmlClass([
													"icon-btn",
													this.state.activeLogin
														? "btn-login-orange"
														: "btn-login",
												])}
											></span>
											<span className={styles.menuText}>Login</span>
										</a>

										<Link
											className={styles.menuIconBlock}
											to={
												this.props.locale
													? `/${this.props.locale}/${this.props.config.pageCancellation.slug}`
													: `/${this.props.config.pageCancellation.slug}`
											}
										>
											<span className={`icon-btn btn-cancellation`}></span>
											<span className={styles.menuText}>Kündigung</span>
										</Link>

										<Link
											className={styles.menuIconBlock}
											to={
												this.props.locale
													? `/${this.props.locale}/${this.props.config.pageDisruption.slug}`
													: `/${this.props.config.pageDisruption.slug}`
											}
										>
											<span className={`icon-btn btn-lightning`}></span>
											<span className={styles.menuText}>Störung</span>
										</Link>
									</div>
								</div>

								<div
									className={createHtmlClass([
										this.state.activeMenu.length > 0 ? "" : styles.hidden,
										styles.subMenuContainer,
									])}
									onMouseLeave={(e) => this.closeMenu()}
									role="none"
								>
									<div className={styles.subMenu}>
										{Object.entries(subNavigations).map((entry) => {
											const [id, childList] = entry;
											return (
												<Fragment key={id}>
													<div
														className={
															this.state.activeMenu === id ||
															this.state.activeSubMenu === id
																? ""
																: styles.hidden
														}
													>
														{childList.map((navChild) => {
															return (
																<Link
																	key={navChild.id}
																	className={styles.menuItem}
																	to={
																		this.props.locale
																			? `/${this.props.locale}/${navChild?.link?.slug}`
																			: `/${navChild?.link?.slug}`
																	}
																	onMouseOver={() => {
																		if (
																			navChild.treeParent.id ===
																			this.state.activeSubMenu
																		) {
																			return;
																		}
																		if (!navChild.treeChildren.length) {
																			this.closeSubMenu();
																		} else {
																			this.openSubMenu(navChild.id);
																		}
																	}}
																	// onFocus={(e) => {this.addSubMenu(navChild.id)}}
																	// onClick={(e) => {this.closeSubMenu()}}
																>
																	{navChild.name}
																	{navChild?.treeChildren.length > 0 && (
																		<div className="icon-btn btn-arrow-right" />
																	)}
																</Link>
															);
														})}
													</div>
												</Fragment>
											);
										})}
									</div>

									{this.state.activeSubMenu !== "" && (
										<div className={styles.verticalDivider}></div>
									)}
								</div>

								{this.state.activeSearchMenu &&
									this.state.activeMenu.length < 1 && (
										<div
											ref={this.searchContainer}
											className={styles.searchMenuContainer}
										>
											<SiteSearchBar className={styles.siteSearchBar} />

											{this.state.activeSubMenu !== "" && (
												<div className={styles.verticalDivider}></div>
											)}
										</div>
									)}
							</div>

							<MobileNavigation
								locale={this.props.locale}
								topLevelElements={
									enableTopLevelMenu ? data.allDatoCmsNavigationMain.nodes : []
								}
								navElements={navElements}
								subNavigations={subNavigations}
								layerMap={layerMap}
							/>
						</>
					);
				}}
			/>
		);
	}
}

const query = graphql`
	query PageHeaderQuery {
		allDatoCmsNavigationMain(
			sort: { fields: position, order: ASC }
			filter: { root: { eq: true }, locale: { eq: "de" } }
		) {
			nodes {
				id
				name
				link {
					... on DatoCmsPage {
						id
						originalId
						slug
						internal {
							type
						}
					}
					... on DatoCmsNews {
						id
						originalId
						slug
						internal {
							type
						}
					}
				}
				treeParent {
					id
				}
				treeChildren {
					id
					name
					position
					link {
						... on DatoCmsPage {
							id
							originalId
							slug
							internal {
								type
							}
						}
						... on DatoCmsNews {
							id
							originalId
							slug
							internal {
								type
							}
						}
					}
					treeParent {
						id
					}
					treeChildren {
						id
						name
						position
						link {
							... on DatoCmsPage {
								id
								originalId
								slug
								internal {
									type
								}
							}
							... on DatoCmsNews {
								id
								originalId
								slug
								internal {
									type
								}
							}
						}
						treeParent {
							id
						}
						treeChildren {
							id
							name
							position
							link {
								... on DatoCmsPage {
									id
									originalId
									slug
									internal {
										type
									}
								}
								... on DatoCmsNews {
									id
									originalId
									slug
									internal {
										type
									}
								}
							}
							treeParent {
								id
							}
							treeChildren {
								id
								name
								position
								link {
									... on DatoCmsPage {
										id
										originalId
										slug
										internal {
											type
										}
									}
									... on DatoCmsNews {
										id
										originalId
										slug
										internal {
											type
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default NavTop;
