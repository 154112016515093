import React, { Fragment } from "react";
import createHtmlClass from "../../lib/helper/createHtmlClass";
import * as styles from "./component.module.less";

const Headline = (props) => {
  const {
    className,
    semanticOfHeadline = 'H5 Style',
    accentColor = '#EF7D00',
    children
  } = props

  
  switch (semanticOfHeadline) {
    case "H1":
      return <h1 className={createHtmlClass([styles.h1, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </h1>;
    case "H1 Style":
      return <span className={createHtmlClass([styles.h1, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </span>;
    case "H2":
      return <h2 className={createHtmlClass([styles.h2, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </h2>;
    case "H2 Style":
      return <span className={createHtmlClass([styles.h2, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </span>;
    case "H3":
      return <h3 className={createHtmlClass([styles.h3, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </h3>;
    case "H3 Style":
      return <span className={createHtmlClass([styles.h3, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </span>;
    case "H4":
      return <h4 className={createHtmlClass([styles.h4, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </h4>;
    case "H4 Style":
      return <span className={createHtmlClass([styles.h4, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </span>;
    case "H5":
      return <h5 className={createHtmlClass([styles.h5, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </h5>;
    case "H5 Style":
      return <span className={createHtmlClass([styles.h5, className])}>
        <MulticolorHeadline headline={children} accentColor={accentColor}/>
      </span>;
    default:
      return null;
  }
};

function MulticolorHeadline (props) {
  if (!props.headline) { return null }
  if (typeof props.headline !== 'string') { return null }

  const splittedHeadline = props.headline.split("[")

  return splittedHeadline.map((head, key) => {
    if (head.split("]").length > 1) {
      return (
        <Fragment key={key}>
          <span
            style={{ color: props.accentColor }}
          >{head.split("]")[0]}</span>
          <span>{head.split("]")[1]}</span>
        </Fragment>
      )
    } else {
      return (
        <span key={key}>{head}</span>
      )  
    }
  })
}

export default Headline;
